import React from "react";
import { Routes, Route } from "react-router-dom";
import { PageNotFoundScreen } from "../screens/ErrorScreen";
import ProtectedRoute from "./ProtectedRoute";
import { RecruiterLandingScreen } from "../screens/LandingScreen";
import { RecruiterDashboard } from "../screens/Dashboard";
const RecruiterRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<RecruiterLandingScreen />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<RecruiterDashboard />} />
      </Route>
      <Route path="*" element={<PageNotFoundScreen />} />
    </Routes>
  );
};

export default RecruiterRoute;
