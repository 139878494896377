import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import JobCategories from "./components/JobCategories";
import Mentoring from "./components/Mentoring";
import CTA from "./components/CTA";
import Footer from "./components/Footer";
const UserLandingScreen = () => {
  return (
    <main className="flex min-h-screen flex-col bg-white">
      <Header />
      <Hero />
      <Features />
      <JobCategories />
      <Mentoring />
      <CTA />
      <Footer />
    </main>
  );
};

export default UserLandingScreen;
