import { Button } from "@mui/joy";

const categories = [
  "Technology",
  "Marketing",
  "Finance",
  "Healthcare",
  "Education",
  "Design",
  "Sales",
  "Engineering",
];

export default function JobCategories() {
  return (
    <section id="jobs" className="bg-gray-50 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Explore Job Categories
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Discover opportunities in various industries and fields.
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 text-center sm:grid-cols-2 lg:grid-cols-4">
            {categories.map((category) => (
              <Button variant="outlined" key={category} className="text-lg">
                {category}
              </Button>
            ))}
          </div>
        </div>
        <div className="mt-16 flex justify-center">
          <Button size="lg">View All Jobs</Button>
        </div>
      </div>
    </section>
  );
}
