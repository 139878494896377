import React from "react";
import "./ApplicationLayout.scss";
import AdminRoute from "../../routes/AdminRoute";

const AdminApplicationLayout = () => {
  return (
    <div>
      <AdminRoute />
    </div>
  );
};

export default AdminApplicationLayout;
