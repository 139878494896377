import React from "react";
import "./ApplicationLayout.scss";
import EnterpriseRoute from "../../routes/EnterpriseRoute";

const EnterpriseApplicationLayout = () => {
  return (
    <div>
      <EnterpriseRoute />
    </div>
  );
};

export default EnterpriseApplicationLayout;
