import React from "react";
import { Routes, Route } from "react-router-dom";
import { PageNotFoundScreen } from "../screens/ErrorScreen";
import ProtectedRoute from "./ProtectedRoute";
import { EnterpriseLandingScreen } from "../screens/LandingScreen";
import EnterpriseDashboard from "../screens/Dashboard/Enterprise/EnterpriseDashboard";
const EnterpriseRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<EnterpriseLandingScreen />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<EnterpriseDashboard />} />
      </Route>
      <Route path="*" element={<PageNotFoundScreen />} />
    </Routes>
  );
};

export default EnterpriseRoute;
