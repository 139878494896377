import React from "react";
import { Routes, Route } from "react-router-dom";
import { PageNotFoundScreen } from "../screens/ErrorScreen";
import ProtectedRoute from "./ProtectedRoute";
import { AdminLandingScreen } from "../screens/LandingScreen";
import AdminDashboard from "../screens/Dashboard/Admin/AdminDashboard";
const AdminRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminLandingScreen />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<AdminDashboard />} />
      </Route>
      <Route path="*" element={<PageNotFoundScreen />} />
    </Routes>
  );
};

export default AdminRoute;
